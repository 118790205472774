import sha256 from 'crypto-js/sha256';
import { localStorageNames } from "../configs/constants"


const { __USER_DATA } = localStorageNames
const CryptoJS = require("crypto-js");
var SHA256 = sha256(process.env.REACT_APP_ENCRYPTION_SECRET_KEY).toString();

// --------->>>>>>>>>> THIS IS FOR ENCRYPTION AND DECRYPTION <<<<<<<<<<<<<----------------------
const JsonFormatter = {
    stringify: function (cipherParams) {
        // create json object with ciphertext
        const jsonObj = {
            ciphertext: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
            iv: cipherParams.iv.toString(),
            salt: cipherParams.salt.toString()
        };
        return `${jsonObj.iv}.${jsonObj.ciphertext}.${jsonObj.salt}`
    },
    parse: function (inputData) {
        const inputParams = inputData.split('.')
        if (inputParams.length !== 3) throw new Error('Invalid Data Format!')
        const cipherParams = {
            iv: CryptoJS.enc.Hex.parse(inputParams[0]),
            ciphertext: CryptoJS.enc.Base64.parse(inputParams[1]),
            salt: CryptoJS.enc.Hex.parse(inputParams[2]),
        }
        return cipherParams;
    }
};

// Function to encrypt data using AES-256-CCM
export const encryptData = (data) => {
    const cipher = CryptoJS.AES.encrypt(JSON.stringify(data), SHA256, { format: JsonFormatter }).toString();
    return cipher
};

// Function to decrypt data using AES-256-CCM
export const decryptData = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, SHA256, { format: JsonFormatter });
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const encryptMode = isEncryptModeOn()
    if (encryptMode) {
        return JSON.parse(decryptedData)
    } else {
        return decryptedData
    }
};

// Function to Know App is Runnning on production or development for ENCRYPTION AND DECRYPTION
export const isEncryptModeOn = () => {
    if (process.env.REACT_APP_ENCRYPTION_MODE === "production") return true
    return false
}

// Function Work For Error Handling and Unauthorized User Data Delete Form localStorage
export const errorStatusHandler = (code, message) => {
    if (code === 401 || code === "401") {
        alert(message)
        localStorage.removeItem(__USER_DATA)
        window.location.href = '/login'
    } else if (code === 403 || code === "403") {
        alert(message)
        localStorage.removeItem(__USER_DATA)
        window.location.href = '/login'
        return true
    } else if (code === 409 || code === "409") {
        alert(message)
        localStorage.removeItem(__USER_DATA)
        window.location.href = '/login'
        return true
    } else {
        return false
    }
}