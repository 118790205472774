import { useEffect, useState } from "react";
import LoaderRing from "../../components/ui/common/LoaderRing";
import verifyMailScreen from "../../assets/verifyMailScreen.svg"
import somethingwentwrong from "../../assets/somethingwentwrong.svg"
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../utility/hooks/UseStore";
import { useCustomSearchParams } from "../../utility/hooks/useCustomSearchParams";


const VerifyEmail = () => {
    // this is for globle use
    const navigate = useNavigate()

    // this is for accessing complete store
    const store = useStore()

    // this is for getting search params in object
    const params = useCustomSearchParams()

    // this is for showing loader when there is no final output run loader 
    const [loader, setLoader] = useState(true)

    // this state will true after verification will successfull from backend side 
    const [verificationSuccessful, setVerificationSuccessful] = useState(true)

    // this state is used for showing user name after successfull verification
    const [userName, setUserName] = useState('')

    // this effect first check user has authtoken in query or not 
    // if user has auth token then it will call to backend for verifing token
    // if token will false or something wrong then its just turn of loader and set verification failed and shows UI 
    // if token is correct than it will call another api in backend for email verification status and shows verification done UI
    useEffect(() => {
        if (params !== undefined) {
            if (params && params.authToken) {
                store.userRegister.userVerify(params.authToken, setLoader, setUserName, setVerificationSuccessful)
            } else {
                setVerificationSuccessful(false)
                setLoader(false)
            }
        }
    }, [params])


    return (
        <>
            {loader ?
                <LoaderRing />
                :
                verificationSuccessful ?
                    <div className="w-full h-[100vh] bg-[#1C1C26] relative">
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-[40px] items-center justify-center">
                            <img src={verifyMailScreen} alt="verifyMailScreen" />
                            <div className="font-Inter text-center">
                                <h3 className="font-semibold text-[#FFFFFF] text-2xl">Hello {userName}</h3>
                                <p className="font-medium text-[#ABABC0] text-xl py-[40px]">
                                    Thank you! Your email has been verified,<br/> and your account is now active.<br/> To log in, please click the link below.
                                </p>
                                <button className="bg-[#5446D0] rounded-md py-[13px] px-[70px] text-center font-semibold text-xl text-[#FFFFFF] font-Inter" onClick={() => { navigate('/login') }}>Go to log in</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="w-full h-[100vh] bg-[#1C1C26] relative">
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-[40px] items-center justify-center">
                            <img src={somethingwentwrong} alt="somethingwentwrong" />
                            <div className="font-Inter text-center">
                                <p className="font-medium text-[#ABABC0] text-[22px] pt-[40px] pb-[20px]">opps!</p>
                                <p className="font-medium text-[#ABABC0] text-xl pb-[40px]">
                                This link is expired.
                                </p>
                                <Link to="/contact" className="bg-[#5446D0] rounded-md py-[13px] px-[70px] text-center font-semibold text-xl text-[#FFFFFF] font-Inter">Contact Us</Link>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default VerifyEmail;