import authRoute from "./authRoutes/authRoute"
import dashboardRoutes from "./dashboardRoutes/dashboardRoutes"
import videoRoute from "./videoRoutes/videoRoute"


const Routes = [
    ...authRoute,
    ...videoRoute,
    ...dashboardRoutes
]

export { Routes }