import { useEffect, useState } from "react";
import plus_icon from "../../assets/icons/plus.svg";
import excel_upload from "../../assets/icons/excel_upload.svg";
import exclamation_mark from "../../assets/icons/exclamation_mark_red.png";
import { useParams } from "react-router-dom";
import { localStorageNames } from "../../configs/constants";
import { useStore } from "../../utility/hooks/UseStore";
const { __USER_DATA } = localStorageNames;

const AddExcelModel = ({
  setAddExcelModel,
  setApiCallLoader,
  setGetExcelListFromStore,
}) => {
  const store = useStore();
  const [excel, setExcel] = useState({});
  const [excelName, setExcelName] = useState("");
  const [excelNameError, setExcelNameError] = useState("");
  const [error, setError] = useState("");
  const { id } = useParams();

  // upload excel function
  function UploadExcel() {
    const isError = validateRegisterData();

    if (isError) return;
    setApiCallLoader(true);
    const userData = JSON.parse(localStorage.getItem(__USER_DATA));

    var formdata = new FormData();
    formdata.append("campaignId", id);
    formdata.append("excel", excel);
    formdata.append("excelName", excelName);
    formdata.append("userId", userData._id);

    store.videoStore.uploadExcel({
      formdata,
      setApiCallLoader,
      setAddExcelModel,
      setGetExcelListFromStore,
    });
  }

  // Validation Function for upload Excel
  function validateRegisterData() {
    let isErrorFound = false;

    if (excel.name === undefined) {
      setError("Excel is required.");
      isErrorFound = true;
    } else if (
      !(
        excel.name.endsWith(".xlsx") ||
        excel.name.endsWith(".xml") ||
        excel.name.endsWith(".xls")
      )
    ) {
      isErrorFound = true;
      setError("It only supports the .xls and .xlsx file formats.");
    } else {
      setError("");
    }

    if (!excelName) {
      setExcelNameError("Excel Name is required.");
      isErrorFound = true;
    } else {
      setExcelNameError("");
    }

    return isErrorFound;
  }

  useEffect(() => {
    if (error !== "" || excelNameError?.length > 0) {
      validateRegisterData();
    }
  }, [excel, excelName]);

  return (
    <div
      className={` bg-black fixed top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80 flex justify-center items-center`}
    >
      <div className="w-[100%] h-[100%] relative flex justify-center items-center">
        <div
          onClick={() => {
            setAddExcelModel(false);
          }}
          className="w-[100%] h-[100%] absolute opacity-[0.7] top-0 left-0 z-[1]"
        ></div>
        <div className="w-[450px] p-[20px] bg-[#1E1E29] flex flex-col items-center z-[1] rounded-[8px]">
          <img
            src={plus_icon}
            alt=""
            onClick={() => {
              setAddExcelModel(false);
            }}
            className="cursor-pointer rotate-45 self-end"
          />
          <div className="w-[100%]">
            <div className="w-[100%] relative">
              <label
                htmlFor="campaign"
                className="font-Inter text-[14px] font-semibold text-[#fff] tracking-[0.32px] leading-[16.94px]"
              >
                Excel Name <span className="text-[#FF5364]">*</span>
              </label>
              <div className=" relative">
                <input
                  name="excelName"
                  className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px] mt-[10px] pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                  placeholder="Enter Excel Name"
                  maxLength={20}
                  type="text"
                  autoComplete="off"
                  value={excelName}
                  onChange={(e) => {
                    setExcelName(e.target.value);
                  }}
                  autoFocus
                ></input>
                <p className="text-[12px] pt-1 absolute bottom-[-18px] leading-[14.52px] font-medium text-[#FF5364]">
                  {excelNameError ? excelNameError : ""}
                </p>
              </div>
              {excelNameError?.length > 0 && (
                <img
                  src={exclamation_mark}
                  alt="*"
                  className="absolute top-[50px] right-[10px] w-[20px]"
                />
              )}
            </div>
            <div className="w-[100%] relative mt-[30px]">
              <label
                htmlFor="Excel"
                className="font-Inter font-semibold text-[14px] text-[#fff] tracking-[0.32px] leading-[16.94px]"
              >
                Excel <span className="text-[#FF5364]">*</span>
              </label>
              <div
                className={`mt-[10px] py-[8.5px] px-[8px] rounded-[6px] border border-[#3B3B4F] transition-all w-[100%] flex items-center `}
              >
                <input
                  onChange={(e) => {
                    setExcel(e.target.files[0]);
                  }}
                  type="file"
                  name="Excel"
                  accept=".xlsx, .xls"
                  autoFocus
                  placeholder="Enter Folder Name Here..."
                  className={`absolute w-[100%] h-[54%] top-[38px] left-[0px] p-[10px] cursor-pointer opacity-0 rounded-[3px]`}
                />
                <p className="h-[100%] text-[#ABABC0] bg-[#3B3B4F] flex p-[8px] rounded-[3px] items-center font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px] mr-[10px]">
                  <img src={excel_upload} alt="video" className="mr-[10px]" />
                  Upload Excel
                </p>
                {excel.name === undefined ? (
                  ""
                ) : (
                  <span className="font-Inter font-normal text-[14px] leading-[16.94px] tracking-[0.48px] text-[#ABABC0]">
                    {String(excel.name).length < 20
                      ? excel.name
                      : `${excel.name.substring(0, 20)}...`}
                  </span>
                )}
                {error?.length > 0 && (
                  <img
                    src={exclamation_mark}
                    alt="*"
                    className="absolute top-[50px] right-[10px] w-[20px]"
                  />
                )}
              </div>
              {error && (
                <p className="text-[12px] absolute bottom-[-18px] pt-1 leading-[14.52px] font-medium text-[#FF5364]">
                  {error}
                </p>
              )}
            </div>

            <div className="w-[100%] flex justify-center items-center mt-[30px]">
              <button
                className="rounded-[6px] border-[1px] py-[11px] px-[35px] text-[#FFFFFF] border-[#5446D0] font-Inter font-medium text-[14px] min-w-[130px] leading-[16px] tracking-[0.48px]"
                onClick={() => {
                  setAddExcelModel(false);
                }}
              >
                Cancel
              </button>
              <button
                className="rounded-[6px] border-[1px] py-[11px] px-[35px] text-[#FFFFFF] bg-[#5446D0] border-[#5446D0] font-Inter font-medium text-[14px] min-w-[130px] leading-[16px] tracking-[0.48px] ml-[12px]"
                onClick={() => {
                  UploadExcel();
                }}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExcelModel;
