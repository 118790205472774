const APILoaderRing = () => {
    return (
        <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-80">
            <div className="z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    )
}

export default APILoaderRing