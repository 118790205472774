import React, { useContext, useEffect, useRef, useState } from "react";
import { useStore } from "../../../../utility/hooks/UseStore";


const ScaleRangeInput = (props) => {

  const mainStore = useStore()
  const store = mainStore.videoStore

  const { max, value, onChange } = props;
  const ref = useRef(null);
  const refIsMouseDown = useRef(false);
  const [canvasSize, setCanvasSize] = useState({
    width: 50,
    height: props.height,
  });

  useEffect(() => {
    // update canvas size based on container size
    const handleResize = () => {
      if (ref.current) {
        setCanvasSize({
          width: ref.current.parentElement?.clientWidth ?? 50,
          // height: ref.current.parentElement?.clientHeight ?? props.height,
          height: props.height,

        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      const canvas = ref.current;
      canvas.width = ref.current.parentElement?.clientWidth || 0;
      canvas.height = canvasSize.height;
      canvas.style.width = "100%";

      //   canvas.style.height = '50px'
      //   canvas.style.maxHeight = '50px'
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillStyle = props.backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        props.markings.forEach((marking) => {
          ctx.strokeStyle = marking.color;
          ctx.lineWidth = marking.width;
          ctx.beginPath();
          for (let i = 0; i < max; i += marking.interval) {
            ctx.moveTo(
              (i / max) * Number(ref.current?.parentElement?.clientWidth),
              0
            );
            ctx.lineTo(
              (i / max) * Number(ref.current?.parentElement?.clientWidth),
              marking.size
            );
          }
          ctx.stroke();
        });
      }

      // const newPointer = document.getElementById("pointerID");
      // const scrollElement = document.getElementById("scrollElement22");

      // const isElementScrollable = (element: any) => {
      //   return (
      //     element.scrollHeight > element.clientHeight ||
      //     element.scrollWidth > element.clientWidth
      //   );
      // };

      // if (isElementScrollable(scrollElement)) {

      //   const viewportWidth = window.innerWidth; // Width of the viewport
      //   const elementWidth = newPointer.clientWidth; // Width of the element
      //   const elementRightOffset = viewportWidth - newPointer.getBoundingClientRect().right;
      //   const offsetPercentage = (elementRightOffset / viewportWidth) * 100;



      //   if (offsetPercentage <= 50) {


      //     // scrollElement.scrollBy(0, 2);
      //     scrollElement.scrollLeft += 1;
      //   }
      // }

    }
  }, [
    props.markings,
    props.backgroundColor,
    max,
    canvasSize,
    store.timeLineZoom,
  ]);

  const updateFromMouseEvent = (
    e
  ) => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      const x = e.clientX - rect.left;
      const value = (x / Number(ref.current?.parentElement?.clientWidth)) * max;
      const normalizedValue = Math.min(max, Math.max(0, value));
      onChange(normalizedValue);
    }
  };


  // useEffect(() => {
  //     ref.current?.parentElement?.clientWidth,
  //     "this is my with, from timelinezoom"
  //   );
  // }, [store.timeLineZoom]);

  return (
    <div
      className="relative w-[100%] overflow-hidden"
      onMouseDown={(e) => {
        refIsMouseDown.current = true;
        updateFromMouseEvent(e);
      }}
      onMouseUp={(e) => {
        refIsMouseDown.current = false;
      }}
      onMouseMove={(e) => {
        if (refIsMouseDown.current) {
          updateFromMouseEvent(e);
        }
      }}
      onMouseLeave={(e) => {
        refIsMouseDown.current = false;
      }}
    >
      <div style={{ width: `${store.timeLineZoom}` }} className="">
        <canvas height={props.height} ref={ref}></canvas>
      </div>
      <div
        id="pointerID"
        className="rounded-full bg-[#FFFFFF] w-[2px] absolute top-0 left-[0px]"
        style={{
          height: `${props.height + 10}px`,
          transform: `translateX(${(value / max) * Number(ref.current?.parentElement?.clientWidth)
            }px) translateX(0.2px)`,
        }}
      ></div>
    </div>
  );
};

export default ScaleRangeInput;

