import { lazy } from "react"
import Editor from "../../../pages/videoEditor/Editor"
import BlankLayout from "../../../layout/BlankLayout"
import Video from "../../../pages/dashboard/Video"
import PrivateRoute from "../../../components/routeTypes/PrivateRoute"
import PublicRoute from "../../../components/routeTypes/PublicRoute"
const LoaderRing = lazy(() => import('../../../components/ui/common/LoaderRing'))
const DashboardLayout = lazy(() => import('../../../layout/DashboardLayout'))
const Preview = lazy(() => import('../../../pages/videoEditor/Preview'))


const videoRoute = [
    {
        path: '/videos',
        element: <PrivateRoute><DashboardLayout><Video /></DashboardLayout></PrivateRoute>,
        loader: <LoaderRing />,
    },
    {
        path: '/video-editor/:id',
        element: <PrivateRoute> <BlankLayout><Editor /></BlankLayout></PrivateRoute>,
        loader: <LoaderRing />,
    },
    {
        path: '/video/preview',
        element: <PrivateRoute> <BlankLayout><Preview /></BlankLayout></PrivateRoute>,
    }
]

export default videoRoute