import { useEffect, useState } from "react"
import { localStorageNames } from "../../configs/constants"
import LoaderRing from "../ui/common/LoaderRing"
import { useNavigate } from 'react-router-dom'
import { observer } from "mobx-react"

const { __USER_DATA } = localStorageNames
const PrivateRoute = observer(({ children }) => {

    const navigate = useNavigate()
    const [isAuthenticate, setAuthenticate] = useState(false)

    useEffect(() => {
        if (localStorage.getItem(__USER_DATA)) {
            setAuthenticate(true)
        } else {
            setAuthenticate(false)
            navigate('/login')
        }
    }, [])

    return (
        <div className="w-[100%]">
            {isAuthenticate ? children : <LoaderRing />}
        </div>
    )
})

export default PrivateRoute