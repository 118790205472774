import React from "react";
import { observer } from "mobx-react";
// import { AudioResource } from "../entity/AudioResource";
// import { UploadButton } from "../shared/UploadButton";
import { useStore } from "../../../../utility/hooks/UseStore";
import { formatTimeToMinSec } from "../../../../utility/videoEditorUtils";
import { MdAdd } from "react-icons/md";


const AudioResourcesPanel = observer(() => {
    const mainStore = useStore()
    const store = mainStore.videoStore;


    const ref = React.useRef(null);
    const [formatedAudioLength, setFormatedAudioLength] = React.useState("00:00");

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (!file) return;
        store.addAudioResource(URL.createObjectURL(file));
    };

    return (
        <>
            <div className="text-sm px-[16px] pt-[16px] pb-[8px] font-semibold">
                Add Audio
            </div>
            {store.audios.map((audio, index) => {
                return (
                    <div>
                        <div className="rounded-lg overflow-hidden items-center bg-slate-800 m-[15px] flex flex-col relative min-h-[100px] ">
                            <div className="bg-[rgba(0,0,0,.25)] text-white py-1 absolute text-base top-2 right-2">
                                {formatedAudioLength}
                            </div>
                            <button
                                className="hover:bg-[#00a0f5] bg-[rgba(0,0,0,.25)] rounded z-10 text-white font-bold py-1 absolute text-lg bottom-2 right-2 outline-none"
                                onClick={() => store.addAudio(index)}
                            >
                                <MdAdd size="25" />
                            </button>
                            <audio onLoadedData={() => {
                                const audioLength = ref.current?.duration ?? 0;
                                setFormatedAudioLength(formatTimeToMinSec(audioLength));
                            }}
                                ref={ref}
                                className="max-h-[100px]   max-w-[150px] min-h-[50px] min-w-[100px]"
                                // controls
                                src={audio}
                                id={`audio-${index}`}
                            ></audio>
                        </div>
                    </div>
                )
            })}



            <div className="mb-5 ">
                <label htmlFor="fileInput" className='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold text-center mx-2 py-2 px-4 rounded '  >
                    <input
                        id="fileInput"
                        type="file"
                        accept='audio/mp3,audio/*'
                        className=" hidden"
                        onChange={handleFileChange}
                        required
                    />
                    Upload
                </label>
            </div>
        </>
    );
});

export default AudioResourcesPanel;
