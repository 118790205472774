import { observer } from "mobx-react";
import { useRef, useState } from "react";
import UseOutsideClick from "./closeToggel";
import droparrow from '../../assets/dropdown-arrow.svg';

const CustomSelect = ({ stylesForOptions, styles, options, value, onChange, name }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    const handleSelect = (name, value) => {
        onChange({ target: { name, value } });
        setIsOpen(false);
    };

    UseOutsideClick(ref, () => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    return (
        <div ref={ref} className={`${styles} cursor-pointer relative `} onClick={() => setIsOpen(!isOpen)}>
            <div className="w-[100%] capitalize flex items-center justify-between whitespace-nowrap ">
                {value.length < 1 ? `Select ${name}` : value}
                <img src={droparrow} alt="arrow" className={`${isOpen ? 'transform rotate-[180deg]' : 'transform rotate-[0deg]'}`}></img>
            </div>
            {isOpen &&
                <ul className={`${stylesForOptions} absolute  top-[100%] bg-[#17171E] left-0 border border-[#2C2D3C] rounded-b-lg`}>
                    {name !== "countryCode" &&
                        <li className={`${value.length < 1 ? "text-[#FFFFFF]" : "text-[#ABABC0]"} hover:bg-[#5446D0] bg-[#17171e] py-[4px] px-[16px] w-[100%]`} onClick={(e) => { handleSelect(name, "") }}>
                            Select
                        </li>
                    }
                    {options.map((option, i) =>
                        <li value={option} key={i} className={`${value === option ? "text-[#FFFFFF]" : "text-[#ABABC0]"} w-[100%] whitespace-nowrap hover:bg-[#5446D0] bg-[#17171e] py-[4px] px-[16px]`} onClick={(e) => { handleSelect(name, option) }}>
                            {option}
                        </li>
                    )}
                </ul>
            }
        </div>

    );
};

export default observer(CustomSelect);