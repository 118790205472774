'use client'
import React from "react";
import { observer } from "mobx-react";
import { AnimationResource } from "../../entity/AnimationResource";
import { getUid } from "../../../../utility/videoEditorUtils";
import left_arrow_icon from "../../../../assets/icons/left_arrow.svg"
import { useStore } from "../../../../utility/hooks/UseStore";

export const AnimationsPanel = observer(() => {
    const mainStore = useStore()
    const store = mainStore.videoStore

    const selectedElement = store.selectedElement;
    const selectedElementAnimations = store.animations.filter((animation) => {
        return animation.targetId === selectedElement?.id;
    });
    const hasFadeInAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "fadeIn";
    });
    const hasFadeOutAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "fadeOut";
    });
    const hasSlideInAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "slideIn";
    });
    const hasSlideOutAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "slideOut";
    });

    const hasConsantAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "breathe";
    });

    const hasZoomInAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "zoomIn";
    });
    const hasZoomOutAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "zoomOut";
    })

    const hasBounceInAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "bounceIn"
    })
    const hasBounceOutAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "bounceOut"
    })

    const hasLightSppedOutAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "lightspeedOut"
    })
    const hasLightSppedInAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "lightspeedIn"
    })

    // const hasRotateAnimation = selectedElementAnimations.some((animation) =>{
    //   return animation.type === "rotate"
    // })

    const hasTypeWriterAnimation = selectedElementAnimations.some((animation) => {
        return animation.type === "typewriter"
    })


    return (
        <>
            <div className=" p-[20px] ">
                <div className="mb-[20px]">
                    <div className="flex items-center border-b pb-[10px]">
                        <img src={left_arrow_icon} alt="arrow" className="cursor-pointer p-[10px] rounded bg-[#1C1C26]" onClick={() => { store.setSelectedMenuOption("TextPanel") }} />
                        <h1 className="font-Inter font-semibold pl-[14px] text-lg text-[#FFFFFF]">Animations</h1>
                    </div >
                </div>
                <div className="font-Inter font-semibold pl-[14px] tracking-[0.32px] leading-[16.94px] text-[#FFFFFF] mb-[12px]">
                    Start
                </div>
                {/* {selectedElement ? */}
                <div
                    className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] px-[16px] py-[6px]  hover:text-[#FFFFFF] ${hasFadeInAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasFadeInAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "fadeIn",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {},
                            });
                    }}
                >
                    Fade In
                </div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "fadeIn" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}
                {/* {selectedElement ? */}
                <div
                    className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasSlideInAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasSlideInAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "slideIn",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {
                                    direction: "left",
                                    useClipPath: false,
                                    textType: "none",
                                },
                            });
                    }}
                >
                    Slide In
                </div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "slideIn" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}
                {/* {selectedElement ? */}
                <div
                    className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasZoomInAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasZoomInAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "zoomIn",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {},
                            });
                    }}
                >
                    Zoom In
                </div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "zoomIn" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}
                {/* {selectedElement ? */}
                <div className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasBounceInAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasBounceInAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "bounceIn",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {},
                            });
                    }}>Bounce In</div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "bounceIn" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}
                {/* {selectedElement ? */}
                <div className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasLightSppedInAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasLightSppedInAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "lightspeedIn",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {
                                    direction: "left",
                                    useClipPath: false,
                                    textType: "none",
                                },
                            });
                    }}>lightSpeed In</div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "lightspeedIn" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}
                <br></br>

                <div className="font-Inter font-semibold pl-[14px] tracking-[0.32px] leading-[16.94px] text-[#FFFFFF] mb-[12px]">
                    End
                </div>

                {/* {selectedElement ? */}
                <div
                    className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasFadeOutAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasFadeOutAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "fadeOut",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {},
                            });
                    }}
                >
                    Fade Out
                </div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "fadeOut" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}
                {/* {selectedElement ? */}
                <div
                    className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasSlideOutAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasSlideOutAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "slideOut",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {
                                    direction: "right",
                                    useClipPath: false,
                                    textType: "none",
                                },
                            });
                    }}
                >
                    Slide Out
                </div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "slideOut" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}

                {/* {selectedElement ? */}
                <div className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasZoomOutAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasZoomOutAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "zoomOut",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {},
                            });
                    }}>
                    Zoom Out
                </div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "zoomOut" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}

                {/* {selectedElement ? */}
                <div className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasBounceOutAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasBounceOutAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "bounceOut",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {},
                            }); //
                    }}> Bounce Out</div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "bounceOut" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}
                {/* {selectedElement ? */}
                <div className={`font-[400px] text-[14px] leading-[16.94px] tracking-[0.48px] mt-2 px-[16px] py-[6px] hover:bg-[#3B3B4F] hover:text-[#FFFFFF] ${hasLightSppedOutAnimation ? "text-[#FFFFFF] bg-[#3B3B4F]" : "text-[#ABABC0] hover:bg-[#3B3B4F]"}  font-Inter cursor-pointer`}
                    onClick={() => {
                        !hasLightSppedOutAnimation &&
                            store.addAnimation({
                                id: getUid(),
                                type: "lightspeedOut",
                                targetId: selectedElement?.id ?? "",
                                duration: 1000,
                                properties: {
                                    direction: "left",
                                    useClipPath: false,
                                    textType: "none",
                                },
                            });
                    }}>
                    LightSpeed Out
                </div>
                {/* : null} */}
                {selectedElementAnimations && selectedElementAnimations.map((animation) =>
                    animation.type === "lightspeedOut" ? <AnimationResource key={animation.id} animation={animation} /> : ""
                )}

                {/* {selectedElement && !hasTypeWriterAnimation ? (
          <div className="text-sm px-[16px] py-[8px] font-medium hover:bg-[#3B3B4F] text-[#FFFFFF] font-Inter cursor-pointer"
            onClick={() => {
              store.addAnimation({
                id: getUid(),
                type: "typewriter",
                targetId: selectedElement?.id ?? "",
                duration: 1000,
                properties: {
                  direction: 'left',
                  textType: 'character', 
                  amplitude: 5,
                  frequency: 0.5,
                }
              })
            }}
          >Add TypeWriter</div>
        ) : null} */}
                {/* {selectedElementAnimations.map((animation) => {
                    return <AnimationResource key={animation.id} animation={animation} />;
                })} */}
            </div>
        </>
    );
});
