import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import { useStore } from "../../../../utility/hooks/UseStore";
import useOutsideClick from "../../../../utility/hooks/useOutsideClick";

import merge_list_icon from "../../../../assets/icons/mergeMenu.svg";
import a_icon from "../../../../assets/icons/a.svg";
import a2_icon from "../../../../assets/icons/a2.svg";
import text_left_icon from "../../../../assets/icons/text_left.svg";
import text_center_icon from "../../../../assets/icons/text_center.svg";
import text_right_icon from "../../../../assets/icons/text_right.svg";
import capital_icon from "../../../../assets/icons/capital_text.svg";
import small_text_icon from "../../../../assets/icons/small_text.svg";
import underline_icon from "../../../../assets/icons/underline.svg";
import italic_font_icon from "../../../../assets/icons/italic_font.svg";
import opacity_icon from "../../../../assets/icons/opacity.svg";
import style_icon from "../../../../assets/icons/style.svg";
import animation_icon from "../../../../assets/icons/animation.svg";
import remove_icon from "../../../../assets/remove_icon.svg";
import plus_icon from "../../../../assets/icons/plus.svg";
import w_icon from "../../../../assets/icons/w.svg";
import h_icon from "../../../../assets/icons/h.svg";
import droparrow from "../../../../assets/dropdown-arrow.svg";
import radius_icon from "../../../../assets/icons/radius.svg";
import timeline_icon from "../../../../assets/icons/timeline.svg";
import add_icon from "../../../../assets/icons/add.svg";
import padding_x_icon from "../../../../assets/icons/paddingx.svg";
import padding_y_icon from "../../../../assets/icons/paddingy.svg";
import scale_x_icon from "../../../../assets/icons/scalex.svg";
import scale_y_icon from "../../../../assets/icons/scaley.svg";
// import position_x from "../../../../assets/postion-x.png";
// import position_y from "../../../../assets/position-y.png";
import { colorToHex } from "../../../commanComponents/colorTextToHexaCode";
import fonticon from "../../../../assets/font_size.png"
import stroke_icon from "../../../../assets/icons/stroke.svg";

export const TextResourcesPanel = observer(() => {
  const ref = useRef();

  const [inputText, setInputText] = useState("");
  const [fontWeight, setFontWeight] = useState();
  const [fontFamily, setFontFamily] = useState("");
  const [fontSize, setFontSize] = useState();
  const [fill, setFill] = useState("");
  const [btnBg, setBtnBg] = useState("transparent");
  const [btnWidth, setBtnWidth] = useState();
  const [underLine, setUnderLine] = useState();
  const [italic, setItalic] = useState("");
  const [btnHeight, setBtnHeight] = useState();
  const [border, setBorder] = useState();
  const [borderColor, setBorderColor] = useState("#000000");
  const [borderRadius, setBorderRadius] = useState();
  const [angle, setAngle] = useState();
  const [position, setPosition] = useState();
  const [txtOpacity, setTxtOpacity] = useState();
  const [btnOpacity, setBtnOpacity] = useState();
  const [lineHeight, setlineHeight] = useState();
  const [charSpacing, setcharSpacing] = useState();
  const [fixedWidth, setFixedWidth] = useState(false);
  const [paddingX, setPaddingX] = useState(0);
  const [paddingY, setPaddingY] = useState(0);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [positionX, setpositionX] = useState(0);
  const [positionY, setpositionY] = useState(0);

  const [selectedButton, setSelectedButton] = useState(null);
  const [style, setStyle] = useState(false);
  const [outline, setoutline] = useState("#000000");
  const [outlineWidth, setOutlineWidth] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  // const [startTime, setStartTime] = useState();
  // const [endTime, setEndTime] = useState()

  useOutsideClick(ref, () => {
    if (popupVisible) {
      setPopupVisible(false);
    }
  });

  const mainStore = useStore();
  const store = mainStore.videoStore;

  const selectedElement = store.selectedElement;
  const editorElement = selectedElement?.id;

  const handleInputChange = (e) => {
    const newText = e.target.value;
    setInputText(newText);
    if (editorElement) {
      store.updateText(editorElement, newText);
    }
  };

  const handleFontFamilyChange = (e) => {
    const newFontFamily = e.target.value;
    setFontFamily(newFontFamily);

    // if (selectedButton) {
    //   (selectedButton).set("fontFamily", newFontFamily);
    //   store.canvas?.renderAll();
    // }
    if (editorElement) {
      store.updateFontFamily(editorElement, newFontFamily);
    }
  };

  const handleFontSizeChange = (e) => {
    const newSize = parseFloat(e.target.value);
    setFontSize(newSize);
    // if (selectedButton) {
    //   (selectedButton as any).set("fontSize", newSize);
    //   store.canvas?.renderAll();
    // }
    if (editorElement) {
      store.updateFontSize(editorElement, newSize);
    }
  };

  const handelFontWeight = (e) => {
    const newWeight = parseInt(e.target.value, 10);
    setFontWeight(newWeight);
    if (selectedButton) {
      selectedButton.set("fontWeight", newWeight);
      store.canvas?.renderAll();
    }
    if (editorElement) {
      store.updateFontWeight(editorElement, newWeight);
    }
  };

  const handleLineHeightChange = (e) => {
    const newLineHeight = parseFloat(e.target.value);
    setlineHeight(newLineHeight);
    if (editorElement) {
      store.updateLineHeight(editorElement, newLineHeight);
    }
  };

  const handleCharSpacingChange = (e) => {
    const newCharSpacing = parseInt(e.target.value);
    setcharSpacing(newCharSpacing);
    if (editorElement) {
      store.updateCharSpacing(editorElement, newCharSpacing * 10);
    }
  };

  let cord;

  // const handlePosition = (position) => {
  //   if (editorElement) {
  //     if (position === "left") {
  //       cord = 20;
  //     } else if (position === "right") {
  //       if (!store) return;

  //       const canvasWidth = store?.canvas?.width || 0;

  //       const buttonWidth =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.placement?.width || 0;

  //       const scaleX =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.fabricObject?.scaleX || 0;
  //       const scaledWidth = buttonWidth * scaleX;
  //       cord = canvasWidth - scaledWidth;
  //     } else if (position === "center") {
  //       if (!store) return;
  //       const canvasWidth = store?.canvas?.width || 0;
  //       const canvasHalfWidth = canvasWidth / 2;
  //       const buttonWidth =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.placement.width || 0;

  //       const buttonHalfWidth = buttonWidth / 2;

  //       const scaleX =
  //         store.editorElements.find((item) => item.id === editorElement)
  //           ?.placement.scaleX || 0;
  //       const scaledWidth = buttonHalfWidth * scaleX;

  //       cord = canvasHalfWidth - scaledWidth;
  //     }
  //   }

  //   if (editorElement) {
  //     store.handleAlign(editorElement, cord);
  //     setPosition(position);
  //   }
  // };

  const textAlingment = (position) => {
    if (editorElement) {
      store.handleTextAling(editorElement, position);
    }
  };

  const handleUppercase = (e) => {
    if (editorElement) {
      store.updateUppercase(editorElement);
      const text = inputText;
      if (text !== text.toUpperCase()) {
        setInputText(text?.toUpperCase());
      } else {
      }
    }
  };

  const handleLowercase = (e) => {
    if (editorElement) {
      store.updateLowercase(editorElement);
      const text = inputText;
      if (text !== text?.toLowerCase()) {
        setInputText(text?.toLowerCase());
      } else {
      }
    }
  };

  const handleUnderLine = (e) => {
    setUnderLine(!underLine);
    if (editorElement) {
      store.updateUnderLine(editorElement, !underLine);
    }
  };

  const handleItalic = (e) => {
    const newItalic = italic === "normal" ? "italic" : "normal";
    setItalic(newItalic);
    if (editorElement) {
      store.updateItalic(editorElement, newItalic);
    }
  };

  const handleFontColorChange = (e) => {
    const newColor = e.target.value;
    setFill(newColor);
    if (selectedButton) {
      selectedButton.set("fill", newColor);
      store.canvas?.renderAll();
    }
    if (editorElement) {
      store.updateBtnTextColor(editorElement, newColor);
    }
  };

  const handleTxtOpacityChange = (e) => {
    const newOpacity = parseInt(e.target.value);

    setTxtOpacity(newOpacity);
    if (editorElement) {
      store.updateTxtOpacity(editorElement, newOpacity / 100);
    }
  };

  const handlebtnWidthChange = (e) => {
    const newWidth = parseFloat(e.target.value);

    setBtnWidth(newWidth);

    if (editorElement) {
      store.handleTextFixedWidth(editorElement, newWidth);
    }
  };

  const handlebtnHeightChange = (e) => {
    const newHeight = parseInt(e.target.value);
    setBtnHeight(newHeight);
    if (selectedButton) {
      selectedButton.set("width", newHeight);
      store.canvas?.renderAll();
    }
    if (editorElement) {
      store.updateHeight(editorElement, newHeight);
    }
  };

  const handleBtnBgChange = (e) => {
    const newColor = e.target.value;
    setBtnBg(newColor);
    if (selectedButton) {
      selectedButton.set("backgroundColor", newColor);
      store.canvas?.renderAll();
    }
    if (editorElement) {
      store.updateBackgroundColor(editorElement, newColor);
    }
  };

  const handleBtnOpacityChange = (e) => {
    const newOpacity = parseInt(e.target.value);

    setBtnOpacity(newOpacity);
    if (editorElement) {
      store.updateBtnOpacity(editorElement, newOpacity / 100);
    }
  };

  const handleBorderRadius = (e) => {
    const newBorderRadius = parseFloat(e.target.value);
    setBorderRadius(newBorderRadius);
    if (editorElement) {
      store.updateBorderRadius(editorElement, newBorderRadius);
    }
  };

  const handleAngle = (e) => {
    // const element = store.editorElements.find(
    //   (item) => item.id === editorElement
    // );

    // element.placement.rotation = 20;

    // store.refreshElements();
    if (editorElement) {
      store.handleangle(editorElement);
    }

    // const newAngle = parseInt(e.target.value);
    // setAngle(newAngle);
    // if (editorElement) {
    //   store.updateAngle(editorElement, newAngle);
    // }
  };

  const handleOutline = (e) => {
    const newColor = e.target.value;
    setoutline(newColor);
    if (editorElement) {
      store.updateOutline(editorElement, newColor);
    }
  };

  const handleOutlineWidth = (e) => {
    const newBorder = parseInt(e.target.value);
    setOutlineWidth(newBorder);
    if (editorElement) {
      store.updateOutlineWidth(editorElement, newBorder);
    }
  };

  const handleMinusClick = () => {
    if (outlineWidth > 0) {
      setOutlineWidth((outlineWidth) => outlineWidth - 1);
    }
  };

  const handlePlusClick = () => {
    if (outlineWidth < 100) {
      setOutlineWidth((outlineWidth) => outlineWidth + 1);
    }
  };

  useEffect(() => {
    if (store.selectedElement && store.selectedElement.type === "text") {
      const element = store.selectedElement;

      setInputText(element.properties.text);
      setFontFamily(element.properties.fontFamily);
      setFontSize(element.properties.fontSize);
      setFontWeight(element.properties.fontWeight);
      setlineHeight(element.properties.lineHeight);
      setcharSpacing(element.properties.charSpacing / 10);
      setUnderLine(element.properties.underline);
      setItalic(element.properties.fontStyle);
      setFill(element.properties.textColor);
      setTxtOpacity(element.properties.opacity * 100);
      setBtnWidth(element.properties.fixedWidth);
      setBtnHeight(element.properties.height);
      setBtnBg(element.properties.backgroundColor);
      setBtnOpacity(element.properties.btnOpacity * 100);
      setBorderRadius(element.properties.borderRadius);
      setFixedWidth(element.properties.fixedWidth);
      setPaddingX(element.properties.paddingX);
      setPaddingY(element.properties.paddingY);
      setScaleX(element.placement.scaleX);
      setScaleY(element.placement.scaleY);
      setpositionX(element.placement.x)
      setpositionY(element.placement.y)
      setBorder(element?.properties?.strokeWidth);
      setBorderColor(element?.properties?.stroke);
    }
  }, [store.selectedElement]);


  // const handelscale = () => {
  //   store.setSelectedMenuOption("Text");
  //   store.scaledWidth = 0;
  // };

  const handlePopup = (item) => {
    setInputText((prevValue) =>
      prevValue ? prevValue + `, {{${item}}}` : `{{${item}}}`
    );
    const newText = inputText + `, {{${item}}}`;
    store.updateText(editorElement, newText);
    setPopupVisible(false);
  };

  // useEffect(() => {
  //   if (editorElement) {
  //     store.updateText(editorElement, inputText);
  //   }
  // }, [inputText]);

  const headers = store.singleCampaignData[0].headers;

  // this is all logic for converting miliseconds and handle input text
  const [startTimeValue, setStartTimeValue] = useState({
    min: 0,
    sec: 0,
    miliSec: 0,
  });
  const [endTimeValue, setEndTimeValue] = useState({
    min: 0,
    sec: 0,
    miliSec: 0,
  });

  useEffect(() => {
    const startTime = store.selectedElement.timeFrame.start;

    const startMin = Math.floor(startTime / 60000);
    const startSec = Math.floor((startTime - startMin * 60000) / 1000);
    const startMiliSec = startTime - startMin * 60000 - startSec * 1000;

    setStartTimeValue({
      min: startMin,
      sec: startSec,
      miliSec: startMiliSec,
    });

    const endTime = store.selectedElement.timeFrame.end;
    const endMin = Math.floor(endTime / 60000);
    const endSec = Math.floor((endTime - endMin * 60000) / 1000);
    const endMiliSec = endTime - endMin * 60000 - endSec * 1000;
    setEndTimeValue({
      min: endMin,
      sec: endSec,
      miliSec: endMiliSec,
    });
  }, [
    store.selectedElement.timeFrame.start,
    store.selectedElement.timeFrame.end,
  ]);

  const handleTimeChange = (e, from) => {
    if (from === "start") {
      const { name, value } = e.target;

      const tempState = {
        min: startTimeValue.min,
        sec: startTimeValue.sec,
        miliSec: startTimeValue.miliSec,
      };
      tempState[name] = value;

      const min = Number(tempState.min);
      const sec = Number(tempState.sec);
      const miliSec = Number(tempState.miliSec);
      const total_milliseconds =
        Number(min * 60 * 1000) + Number(sec * 1000) + Number(miliSec);

      if (
        Number(total_milliseconds) >= Number(store.maxTime) ||
        Number(total_milliseconds) < 0 ||
        Number(total_milliseconds) >=
        Number(store.selectedElement.timeFrame.end)
      ) {
        toast.error(
          "Time should be less than video duration and not should be negative nor greater than end time"
        );
        return;
      } else {
        store.updateStartEndTime(
          editorElement,
          "start",
          Number(total_milliseconds)
        );
        setStartTimeValue({
          ...tempState,
        });
      }
    } else if (from === "end") {
      const { name, value } = e.target;

      const tempState = {
        min: endTimeValue.min,
        sec: endTimeValue.sec,
        miliSec: endTimeValue.miliSec,
      };
      tempState[name] = value;

      const min = Number(tempState.min);
      const sec = Number(tempState.sec);
      const miliSec = Number(tempState.miliSec);

      const total_milliseconds =
        Number(min * 60 * 1000) + Number(sec * 1000) + Number(miliSec);

      if (
        Number(total_milliseconds) > Number(store.maxTime) ||
        Number(total_milliseconds) <
        Number(store.selectedElement.timeFrame.start)
      ) {
        toast.error("Time should be less than video duration");
        return;
      } else {
        store.updateStartEndTime(editorElement, "end", total_milliseconds);
        setEndTimeValue({
          ...tempState,
        });
      }
    }
  };

  // this is for all handling about fixed width
  const handleFixedWidthOption = (e) => {
    const isChecked = e.target.checked;

    if (isChecked !== false) {
      const element = store.editorElements.find(
        (item) => item.id === editorElement
      );
      const fabricObject = element.fabricObject;
      const canvasWidth = store.canvas.width;

      const width = (fabricObject.width / canvasWidth) * 100;

      store.handleFixedWidth(editorElement, width);
      setFixedWidth(true);
    } else {
      store.handleFixedWidth(editorElement, false);
      setFixedWidth(false);
    }
  };

  // this is for hanlding paddding
  const handlePadding = (from, e) => {
    if (from === "x") {
      store.handlePaddingForText(editorElement, from, e.target.value);
      setPaddingX(e.target.value);
    } else if (from === "y") {
      store.handlePaddingForText(editorElement, from, e.target.value);
      setPaddingY(e.target.value);
    }
  };

  // this is for handling scaling
  const handleScale = (from, e) => {
    if (from === "x") {
      store.handleScaleForText(editorElement, from, e.target.value);
      setScaleX(e.target.value);
    } else if (from === "y") {
      store.handleScaleForText(editorElement, from, e.target.value);
      setScaleY(e.target.value);
    }
  };

  // this is for handling Position
  const handlePosition = (from, e) => {
    if (from === "x") {
      store.handleTextPosition(editorElement, from, e.target.value);
      setpositionX(e.target.value);
    } else if (from === "y") {
      store.handleTextPosition(editorElement, from, e.target.value);
      setpositionY(e.target.value);
    }
  };

  const handleBorder = (e) => {
    const newBorder = parseFloat(e.target.value);
    setBorder(newBorder);
    if (editorElement) {
      store.updateBorder(editorElement, newBorder);
    }
  };

  const handleBorderColorChange = (e) => {
    const newBorderColor = e.target.value;
    setBorderColor(newBorderColor);
    if (editorElement) {
      store.updateBorderColor(editorElement, newBorderColor);
    }
  };

  return (
    <>
      <div>
        {/* <div className="px-[10px] 2xl:px-[20px] pt-[10px] 2xl:pt-[20px]">
                    <h1 className="text-white font-Inter font-semibold text-[18px]">Text</h1>
                </div> */}

        {/* Text  */}
        <div className="p-[10px] 2xl:p-[20px]">
          <div className="flex justify-between items-center pb-[10px] 2xl:pb-[20px] relative">
            <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">
              Text
            </h3>
            <div
              className="flex justify-center font-Inter font-medium text-[#ABABC0] text-[14px] cursor-pointer"
              onClick={() => {
                setPopupVisible(true);
              }}
            >
              <p className="mr-[10px]">{`{{ Variable }}`}</p>
              <img src={merge_list_icon} alt="MergList" />
              {popupVisible && headers?.length > 0 && (
                <div
                  ref={ref}
                  className="absolute bg-[#1C1C26] p-[10px] gap-[5px] z-[100] max-h-[200px] scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent  overflow-auto rounded-[6px] text-[#ABABC0] top-[25px] right-[0px]"
                >
                  {headers &&
                    headers?.map((employee, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handlePopup(employee)
                        }}
                        className=" p-[5px] cursor-pointer hover:bg-[#3B3B4F]"
                      >
                        {employee}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <textarea
            value={inputText}
            maxLength={70}
            onChange={handleInputChange}
            className="w-full bg-[#3B3B4F] rounded p-[10px] 2xl:h-[100px] h-[60px] text-[#FFFFFF] outline-none"
            placeholder="Please enter text"
          />
          <div className="relative py-[12px]  font-Inter font-normal text-sm text-[#FFFFFF]">
            <select
              value={fontFamily}
              onChange={handleFontFamilyChange}
              className={`w-full  appearance-none rounded bg-[#3B3B4F] p-[10px] outline-none`}
            >
              <option value="Roboto">Roboto</option>
              <option value="Bebas Neue">Bebas Neue</option>
              <option value="POPPINS" className="font-[POPPINS]">
                Poppins
              </option>
              <option value="Oswald">Oswald Sans</option>
              <option value="Titillium Web">Titillium Web</option>
              <option value="Dancing" className="font-[Dancing]">
                Dancing
              </option>
            </select>
            <img
              src={droparrow}
              alt="droparrow"
              className=" absolute  transform translate-x-[-50%] top-[50%] right-2"
            ></img>
          </div>

          <div className="pb-[12px] grid grid-cols-12 gap-[5px] 2xl:gap-[10px] font-Inter font-normal text-sm text-[#FFFFFF]">
            {/* <div className="col-span-6 relative">
              <select
                value={fontSize}
                onChange={handleFontSizeChange}
                className="w-full appearance-none rounded bg-[#3B3B4F] p-[10px] max-h-[200px] overflow-auto outline-none"
              >
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="20">20</option>
                <option value="24">24</option>
                <option value="32">32</option>
                <option value="36">36</option>
                <option value="40">40</option>
                <option value="48">48</option>
                <option value="64">64</option>
                <option value="66">66</option>
                <option value="128">128</option>
              </select>
              <img
                src={droparrow}
                alt="droparrow"
                className=" absolute  transform translate-x-[-50%] top-[45%] right-2"
              ></img>
            </div> */}
            <div className="col-span-6 bg-[#3B3B4F] rounded p-[10px] flex items-center gap-[12px] relative">
              <img src={fonticon} alt="fonticon" className=" peer" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Font Size</span>
              <input
                type="number"
                value={fontSize}
                className="bg-transparent w-full font-Inter font-medium text-sm text-[#FFFFFF] outline-none placeholder-white"
                // placeholder="100"
                onChange={handleFontSizeChange}
                step={0.01}
                min={3}
                max={128}
              />
            </div>

            <div className="col-span-6 relative">
              <select
                value={fontWeight}
                onChange={handelFontWeight}
                className={`appearance-none 2xl:text-[14px] text-[12px] rounded w-full bg-[#3B3B4F] p-[10px] outline-none`}
              >
                <option
                  value="100"
                  className={`font-[${fontFamily}] font-thin`}
                >
                  Thin
                </option>
                <option
                  value="200"
                  className={`font-[${fontFamily}] font-extralight`}
                >
                  Extra Light
                </option>
                <option
                  value="300"
                  className={`font-[${fontFamily}] font-light`}
                >
                  Light
                </option>
                <option
                  value="400"
                  className={`font-[${fontFamily}] font-normal`}
                >
                  Normal
                </option>
                <option
                  value="500"
                  className={`font-[${fontFamily}] font-medium`}
                >
                  Medium
                </option>
                <option
                  value="600"
                  className={`font-[${fontFamily}] font-semibold`}
                >
                  Semi Bold
                </option>
                <option
                  value="700"
                  className={`font-[${fontFamily}] font-bold`}
                >
                  Bold
                </option>
                <option
                  value="800"
                  className={`font-[${fontFamily}] font-extrabold`}
                >
                  Extra Bold
                </option>
                <option
                  value="900"
                  className={`font-[${fontFamily}] font-black`}
                >
                  Black
                </option>
              </select>
              <img
                src={droparrow}
                alt="droparrow"
                className=" absolute  transform translate-x-[-50%] top-[45%] right-1 2xl:right-2"
              ></img>
            </div>
          </div>

          <div className="pb-[12px] grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
            <div className="col-span-6 bg-[#3B3B4F] rounded p-[10px] flex items-center gap-[15px]  relative">
              <img src={a_icon} alt="A" className=" peer" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Line Height</span>
              <input
                type="number"
                value={lineHeight}
                className="bg-transparent w-full font-Inter font-medium text-sm text-[#FFFFFF] outline-none placeholder-white"
                placeholder="100"
                onChange={handleLineHeightChange}
                step={0.01}
                min={1}
                max={5}
              />
            </div>

            <div className="col-span-6 bg-[#3B3B4F] rounded p-[10px] flex items-center gap-[15px]  relative">
              <img src={a2_icon} alt="A2" className=" peer" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Letter Spacing</span>
              <input
                type="number"
                value={charSpacing}
                onChange={handleCharSpacingChange}
                className="bg-transparent w-full font-Inter font-medium text-sm text-[#FFFFFF] outline-none placeholder-white"
                placeholder="0"
                step={1}
                max={200}
                min={0}
              />
            </div>
          </div>

          <div className="grid grid-cols-10 lg:grid-cols-7 2xl:grid-cols-10 gap-[5px]">
            <div
              onClick={() => textAlingment("left")}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={text_left_icon}
                alt="TextLeft"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={() => textAlingment("center")}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={text_center_icon}
                alt="TextCenter"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={() => textAlingment("right")}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={text_right_icon}
                alt="TextRight"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleUppercase}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={capital_icon}
                alt="CapitalText"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleLowercase}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px]  px-[8px] rounded cursor-pointer"
            >
              <img
                src={small_text_icon}
                alt="SmallText"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleUnderLine}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px] px-[8px] rounded cursor-pointer"
            >
              <img
                src={underline_icon}
                alt="UnderLine"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
            <div
              onClick={handleItalic}
              className="col-span-2 lg:col-span-1 2xl:col-span-2 bg-[#3B3B4F] py-[6px] px-[8px] rounded cursor-pointer"
            >
              <img
                src={italic_font_icon}
                alt="ItalicFont"
                className="h-[20px] mx-auto w-[20px]"
              />
            </div>
          </div>

          <div className="pt-[12px]  grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
            <div className="col-span-6 bg-[#3B3B4F] flex items-center gap-2 rounded p-[10px]">
              <input
                id="color1"
                className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer"
                type="color"
                value={fill.includes("#") ? fill : colorToHex(fill)}
                onChange={handleFontColorChange}
                placeholder="#FFFFFF"
              />
              <input
                type="text"
                value={fill}
                className="text-[#FFFFFF] outline-none font-Inter font-normal text-sm uppercase bg-transparent w-[calc(100%-25px)]"
                placeholder="#000000"
                onChange={handleFontColorChange}
                maxLength={20}
              />
            </div>

            <div className="col-span-6 p-[10px] flex items-center gap-2 bg-[#3B3B4F] rounded relative">
              <img src={opacity_icon} alt="Eye" className="w-[15px] h-auto object-contain 2xl:w-[20px] peer" />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Opacity</span>
              <input
                type="number"
                min={0}
                max={100}
                value={txtOpacity}
                onChange={handleTxtOpacityChange}
                step={1}
                className="text-center w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        {/* Style */}
        <div className="p-[10px] 2xl:p-[20px]">
          {/* <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">
            Animation
          </h3> */}
          <div className="grid grid-cols-12">
            {/* <button
              onClick={() => setStyle(true)}
              className={`${style ? "bg-[#5446D0]" : "bg-[#3B3B4F]"
                } lg:col-span-6 col-span-12  p-[10px] rounded flex justify-center items-center gap-[10px] outline-none`}
            >
              <img src={style_icon} alt="style" />
              <span className="font-Inter font-normal text-sm text-[#FFFFFF]">
                Style
              </span>
            </button> */}
            <button
              onClick={() => {
                store.setSelectedMenuOption("Animation");
              }}
              className="col-span-12 bg-[#3B3B4F] p-[10px] rounded flex justify-center items-center gap-[10px] outline-none"
            >
              <img src={animation_icon} alt="animation" />
              <span className="font-Inter font-normal text-sm text-[#FFFFFF]">
                Animation
              </span>
            </button>
          </div>
          {/* {style && (
                        <div className="pt-[10px] 2xl:pt-[20px]">
                            <div className="bg-[#3B3B4F] w-full rounded p-[10px] font-Inter font-medium text-sm text-[#ABABC0]">
                                <p>Text Outline</p>
                                <div className="2xl:pt-[20px] pt-[10px] pb-[10px] grid grid-cols-12 gap-3">
                                    <div className="col-span-12 bg-[#272836] flex items-center gap-3 rounded 2xl:p-[10px] p-[5px]">
                                        <input id="color1" className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer" type="color" value={outline} onChange={handleOutline} placeholder="#000000" />
                                        <span className=" text-[#FFFFFF] font-Inter font-normal text-sm uppercase">{outline}</span>
                                    </div>
                                </div>

                                <div className="flex 2xl:gap-[22px] gap-[10px] justify-between bg-[#272836] rounded 2xl:p-[12px] p-[8px]">
                                    <button className="outline-none" onClick={handleMinusClick}>
                                        <img src={minus_icon} alt="minus" />
                                    </button>
                                    <input type="range" className="bg-[#ABABC0] w-full" value={outlineWidth} onChange={handleOutlineWidth} />
                                    <button className="outline-none" onClick={handlePlusClick}>
                                        <img src={plus_icon} alt="plus" />
                                    </button>
                                    <p className="font-Inter font-medium text-sm text-[#FFFFFF]">{outlineWidth}</p>
                                </div>
                            </div>
                        </div>
                    )} */}
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        {/* Border */}

        <div className="p-[10px] 2xl:p-[20px]">
          <h3 className="font-Inter font-medium text-sm text-[#ABABC0]">
            {" "}
            Border
          </h3>

          <div className="pt-[10px] 2xl:pt-[20px] grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
            <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] gap-[12px] rounded relative">
              <img
                src={stroke_icon}
                alt="Stroke"
                className=" w-[20px] h-auto object-contain 2xl:w-auto peer"
              />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Border Width</span>
              <input
                type="number"
                min={0}
                step={0.01}
                value={border}
                onChange={handleBorder}
                className="  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
              />
            </div>
            <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] gap-[15px] rounded relative">
              <img
                src={radius_icon}
                alt="Radius"
                className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
              />
              <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Border Radius</span>
              <input
                type="number"
                min={0}
                value={borderRadius}
                onChange={handleBorderRadius}
                className=" w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                placeholder="0"
                step={0.01}
              />
            </div>
            <div className="col-span-12 2xl:col-span-6 bg-[#3B3B4F] flex items-center gap-[5px] rounded p-[10px]">
              <input
                id="color1"
                className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer"
                type="color"
                value={
                  borderColor.includes("#")
                    ? borderColor
                    : colorToHex(borderColor)
                }
                onChange={handleBorderColorChange}
                placeholder="#FFFFFF"
              />
              <input
                type="text"
                value={borderColor}
                className="text-[#FFFFFF] outline-none font-Inter font-normal text-sm uppercase bg-transparent w-[calc(100%-25px)]"
                placeholder="#000000"
                onChange={handleBorderColorChange}
                maxLength={20}
              />
            </div>
          </div>
        </div>

        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        {/* Background  */}
        <div className="p-[10px] 2xl:p-[20px] font-Inter text-[#FFFFFF]">
          <h3 className="font-medium text-sm text-[#ABABC0]">Background</h3>
          <div className="pt-[12px] grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
            <div className="col-span-12 bg-[#3B3B4F] flex items-center gap-[1px] 2xl:gap-[10px] rounded p-[8px] 2xl:p-[10px]">
              {/* <input id="color1" className="placeholder:text-[12px] text-[12px] 2xl:w-[25px] 2xl:h-[25px] w-[20px] h-[20px] m-0 appearance-none bg-transparent outline-none cursor-pointer" type="color" value={btnBg?.length > 0 ? btnBg : "transparnt"} onChange={handleBtnBgChange} placeholder="#000000" />
                                <span className=" text-[#FFFFFF] font-Inter capitalize font-normal text-sm text-[10px] 2xl:text-[14px]">{btnBg ? btnBg : ""}</span> */}
              <input
                id="color1"
                className="w-[25px] h-[25px] m-0 appearance-none bg-transparent outline-none cursor-pointer"
                type="color"
                value={btnBg.includes("#") ? btnBg : colorToHex(btnBg)}
                onChange={handleBtnBgChange}
                placeholder="#FFFFFF"
              />
              <input
                type="text"
                value={btnBg}
                className="text-[#FFFFFF] outline-none font-Inter font-normal text-sm uppercase bg-transparent w-[calc(100%-25px)]"
                placeholder="#000000"
                onChange={handleBtnBgChange}
                maxLength={20}
              />
              {btnBg !== "transparent" && (
                <img
                  src={remove_icon}
                  alt="remove_icon"
                  className=" pr-2 cursor-pointer"
                  onClick={() => {
                    setBtnBg("transparent");
                    if (editorElement) {
                      store.updateBackgroundColor(editorElement, 'transparent');
                    }
                  }}
                ></img>
              )}
            </div>
          </div>
          <div>
            <div className="2xl:py-[20px] py-[10px] grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
              <div className="col-span-12 flex items-center">
                <p className="text-[14px] font-Inter font-medium text-[#ABABC0]">
                  Fixed Width
                </p>
                <input
                  onChange={(e) => {
                    handleFixedWidthOption(e);
                  }}
                  className="ml-5"
                  checked={fixedWidth === false ? false : true}
                  defaultChecked={fixedWidth === false ? false : true}
                  type="checkbox"
                />
              </div>
            </div>
         

            <div className=" grid grid-cols-12 gap-[5px] 2xl:gap-[10px]">
            {fixedWidth !== false && (
                <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded">
                  <img
                    src={w_icon}
                    alt="width"
                    className=" w-[20px] h-auto object-contain"
                  />
                  <input
                    type="number"
                    min={0}
                    step={0.01}
                    value={btnWidth}
                    onChange={handlebtnWidthChange}
                    className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                    placeholder="0"
                  />
              </div>
            )}
              <div className="col-span-6 p-[8px] 2xl:p-[10px] flex  bg-[#3B3B4F] rounded relative">
                <img
                  src={opacity_icon}
                  alt="Eye"
                  className="w-[15px] h-auto object-contain 2xl:w-[20px] peer"
                />
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Opacity</span>
                <input
                  type="number"
                  min={0}
                  max={100}
                  value={btnOpacity}
                  onChange={handleBtnOpacityChange}
                  className="  w-full text-[14px] text-center focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                  step={1}
                />
              </div>
            </div>

            <div className=" grid grid-cols-12 gap-[5px] 2xl:gap-[10px] pt-[12px]">
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                <img
                  src={padding_x_icon}
                  alt="Angle"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                />
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Padding X</span>
                <input
                  type="number"
                  min={0}
                  max={200}
                  step={0.01}
                  value={paddingX}
                  onChange={(e) => {
                    handlePadding("x", e);
                  }}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                <img
                  src={padding_y_icon}
                  alt="Radius"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                />
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Padding Y</span>
                <input
                  type="number"
                  min={0}
                  max={200}
                  step={0.01}
                  value={paddingY}
                  onChange={(e) => handlePadding("y", e)}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
            </div>
            <div className=" grid grid-cols-12 gap-[5px] 2xl:gap-[10px] pt-[12px]">
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                <img
                  src={scale_x_icon}
                  alt="Angle"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                />
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Scale X</span>
                <input
                  type="number"
                  min={0}
                  max={10}
                  step={0.01}
                  value={Number(scaleX)?.toFixed(2)}
                  onChange={(e) => {
                    handleScale("x", e);
                  }}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                <img
                  src={scale_y_icon}
                  alt="Radius"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                />
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Scale Y</span>
                <input
                  type="number"
                  min={0}
                  max={10}
                  step={0.01}
                  value={Number(scaleY)?.toFixed(2)}
                  onChange={(e) => handleScale("y", e)}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
            </div>
            <div className=" grid grid-cols-12 gap-[5px] 2xl:gap-[10px] pt-[12px]">
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                {/* <img
                  // src={position_x}
                  alt="Angle"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                /> */}
                <p className="pl-1 peer mr-1">X</p>
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Position X</span>
                <input
                  type="number"
                  min={0}
                  step={0.1}
                  value={Number(positionX)}
                  onChange={(e) => {
                    handlePosition("x", e);
                  }}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
              <div className="col-span-6 p-[12px] flex bg-[#3B3B4F] rounded relative">
                {/* <img
                  // src={position_y}
                  alt="Radius"
                  className=" w-[15px] h-auto object-contain 2xl:w-auto peer"
                /> */}
                <p className="pl-1 peer mr-1">Y</p>
                <span className="tooltip hidden peer-hover:block bg-[#000] text-[#fff] min-w-[100px] top-[45px] z-[20] left-[0px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Position Y</span>
                <input
                  type="number"
                  min={0}
                  step={0.1}
                  value={Number(positionY)}
                  onChange={(e) => handlePosition("y", e)}
                  className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                  placeholder="0"
                />
              </div>
            </div>

          </div>
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px]" />

        {/* Time Line  */}
        <div className="px-[10px] 2xl:px-[20px] pt-[10px]">
          <p className=" text-[#ABABC0] font-Inter font-medium text-[14px] pb-[8px] 2xl:pb-[16px]">
            Timeline
          </p>
          <div className=" grid grid-cols-12 gap-[1px] 2xl:gap-[12px]">

            <div className="col-span-12 flex items-end">
              <label className="text-[14px] min-w-[35px] font-Inter self-center font-medium text-[#ABABC0]">
                Start:
              </label>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Min
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "start");
                    }}
                    name="min"
                    type="number"
                    value={Number(startTimeValue.min).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "start");
                    }}
                    name="sec"
                    type="number"
                    value={Number(startTimeValue.sec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Mili Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "start");
                    }}
                    name="miliSec"
                    type="number"
                    value={Number(startTimeValue.miliSec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-12 flex items-end py-[10px]">
              <label className=" text-[14px] min-w-[35px] font-Inter font-medium self-center text-[#ABABC0] ">
                End:
              </label>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Min
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "end");
                    }}
                    name="min"
                    type="number"
                    value={Number(endTimeValue.min).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "end");
                    }}
                    name="sec"
                    type="number"
                    value={Number(endTimeValue.sec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="ml-2 2xl:ml-3">
                <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                  Mili Sec
                </label>
                <div className="flex justify-start items-center">
                  <input
                    onChange={(e) => {
                      handleTimeChange(e, "end");
                    }}
                    name="miliSec"
                    type="number"
                    value={Number(endTimeValue.miliSec).toFixed()}
                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="bg-[#3B3B4F] border-0  h-[1px] " />
        {/* Add Button  */}
        <div className="px-[10px] 2xl:px-[20px] py-[20px]">
          <button
            className="flex items-center justify-center gap-[10px] bg-[#5446D0] font-Inter font-medium text-sm text-[#FFFFFF] w-full p-[10px] rounded outline-none"
            onClick={() => store.setSelectedMenuOption("text")}
          >
            <img src={add_icon} alt="add" /> Add New Text
          </button>
        </div>
      </div>
    </>
  );
});
