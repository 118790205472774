import React from 'react'
import { Outlet } from 'react-router-dom'

const BlankLayout = ({children}) => {
  return (
    <div className='w-[100%] h-[100%]'>
        {children}
    </div>
  )
}

export default BlankLayout