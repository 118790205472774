import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import logo from "../../assets/h_logo.png"
import magnifyingplus from "../../assets/magnifying-plus.png"
import magnifyingminus from "../../assets/magnifying-minus.png"
import plus from "../../assets/icons/plus.svg"
import arrow from "../../assets/timeline_arrow.svg"
import { menuOptions } from "../../configs/videoEditorConfig"
import EditorOptions from '../../components/videoEditor/editorOptions/EditorOptions'
import TimeLine from '../../components/videoEditor/timeline/TimeLine'
import { useStore } from '../../utility/hooks/UseStore'
import { localStorageNames } from "../../configs/constants"
import { fabric } from 'fabric'
import { ElementsPanel } from '../../components/videoEditor/editorOptions/ElementsPanel'
import ExcelListModal from '../../components/videoEditor/CommanExcelModal'
import AddExcelModel from '../../components/videoEditor/AddExcel'
import APICallLoader from '../../components/ui/common/APILoaderRing'
import { observer } from 'mobx-react'
import toast from 'react-hot-toast'


const { __USER_DATA } = localStorageNames
const Editor = () => {
    // this is for general use
    const navigate = useNavigate()
    const store = useStore()
    const [apiCallLoader, setApiCallLoader] = useState(false)

    const { id } = useParams()
    const [campaignData, setCampaignData] = useState(false)
    const [showTimeline, setShowTimeline] = useState(true)

    // this effect will run when its get params from url and get campaign data
    useEffect(() => {
        if (id !== undefined) {
            getData()
            const userData = JSON.parse(localStorage.getItem(__USER_DATA))
            store.videoStore.getExcelListOfSingleCampaign({ campaignID: id, userID: userData._id, setGetExcelListFromStore })
        }
    }, [id])

    const getData = async () => {
        const userData = JSON.parse(localStorage.getItem(__USER_DATA))
        const singleCampaignData = await store.videoStore.getSingleCampaignData({ campaignID: id, userID: userData._id })
        setCampaignData(singleCampaignData[0])
    }

    const videoStore = store.videoStore;
    const videoWidth = videoStore.updatedVideoWidth;
    const videoHeight = videoStore.updatedVideoHeight;

    useEffect(() => {
        var parent = document.getElementById('container');

        if (!parent) return;

        const canvas = new fabric.Canvas('canvas', {
            backgroundColor: "#ededed",
            preserveObjectStacking: true,
        })

        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerColor = "#00a0f5";
        fabric.Object.prototype.cornerStyle = "circle";
        fabric.Object.prototype.cornerStrokeColor = "#0063d8";
        fabric.Object.prototype.cornerSize = 10;

        canvas.on("mouse:down", function (e) {
            if (!e.target) {
                videoStore.setSelectedElement(null);
            }
            videoStore.setSelectedMenuOption('NoSelectedItem')
        });

        videoStore.setCanvas(canvas);

        fabric.util.requestAnimFrame(function render() {

            canvas?.renderAll();
            fabric.util.requestAnimFrame(render);

        });

        window.addEventListener('resize', () => {
            store.videoStore.refreshElements();
        })
    }, [videoStore, videoWidth, videoHeight])


    useEffect(() => {
        if (campaignData !== undefined && campaignData !== null && campaignData !== false) {
            if (campaignData.jsonData) {
                console.log(campaignData.jsonData, "this is the campaign data")

                campaignData.jsonData.map((item, index) => {

                    if (index === 0) {
                        console.log("in this video");

                        const data = campaignData.jsonData.find(element => element.type === 'video')
                        videoStore.addVideo(data.properties.imageObject.src, 100, data.placement, data.timeFrame);

                    }
                    if (item.type === 'text') {
                        console.log("in the final text");

                        videoStore.addText({
                            x: item.placement.x,
                            y: item.placement.y,
                            rotation: item.placement.rotation,
                            scaleX: item.placement.scaleX,
                            scaleY: item.placement.scaleY,
                            angle: item.properties.angle,
                            backgroundColor: item.properties.backgroundColor,
                            borderRadius: item.properties.borderRadius,
                            btnOpacity: item.properties.btnOpacity,
                            charSpacing: item.properties.charSpacing,
                            fixedWidth: item.properties.fixedWidth,
                            fontFamily: item.properties.fontFamily,
                            fontSize: item.properties.fontSize,
                            fontWeight: item.properties.fontWeight,
                            lineHeight: item.properties.lineHeight,
                            outline: item.properties.outline,
                            outlineWidth: item.properties.outlineWidth,
                            paddingX: item.properties.paddingX,
                            paddingY: item.properties.paddingY,
                            text: item.properties.text,
                            textAlign: item.properties.textAlign,
                            textColor: item.properties.textColor,
                            txtOpacity: item.properties.txtOpacity,
                            underline: item.properties.underline,
                            start: item.timeFrame.start,
                            end: item.timeFrame.end,
                            updateStart: item.timeFrame.updateStart,
                            updateEnd: item.timeFrame.updateEnd,
                            strokeWidth: item.properties.strokeWidth,
                            stroke: item.properties.stroke,
                            fontStyle: item.properties.fontStyle,
                        })
                    }
                    else if (item.type === 'audio') {
                        videoStore.addtexttospeech(item.properties.src, item.properties.text, item.placement, item.timeFrame, item.properties);
                    }
                    else if (item.type === 'button') {
                        videoStore.addButton({
                            text: item.properties.text,
                            fontSize: item.properties.fontSize,
                            fontFamily: item.properties.fontFamily,
                            fontWeight: item.properties.fontWeight,
                            textColor: item.properties.textColor,
                            linkUrl: item.properties.linkUrl,
                            backgroundColor: item.properties.backgroundColor,
                            width: item.properties.width,
                            height: item.properties.height,
                            underline: item.properties.underline,
                            fontStyle: item.properties.fontStyle,
                            textAlign: item.properties.textAlign,
                            strokeWidth: item.properties.strokeWidth,
                            stroke: item.properties.stroke,
                            borderRadius: item.properties.borderRadius,
                            alignment: item.properties.alignment,
                            txtOpacity: item.properties.txtOpacity,
                            btnOpacity: item.properties.btnOpacity,
                            charSpacing: item.properties.charSpacing,
                            lineHeight: item.properties.lineHeight,
                            paddingX: item.properties.paddingX,
                            paddingY: item.properties.paddingY,
                            x: item.placement.x,
                            y: item.placement.y,
                            rotation: item.placement.rotation,
                            scaleX: item.placement.scaleX,
                            scaleY: item.placement.scaleY,
                            start: item.timeFrame.start,
                            end: item.timeFrame.end,
                        })
                    }


                    // if (item.type === 'video') {


                    //     // const video = document.getElementById(item.properties.elementId);
                    //     // if (!video) return;


                    //     // video.src = item.properties.src;
                    //     videoStore.addVideo(item.properties.imageObject.src, 100, item.placement, item.timeFrame);
                    // }


                });
            } else {
                videoStore.addVideo(`${process.env.REACT_APP_MEDIA_URL}${campaignData.video}`, 1)
            }
        }
    }, [campaignData])

    const [excelModel, setExcelModel] = useState(false)
    const [addExcelModel, setAddExcelModel] = useState(false)

    const [getExcelListFromStore, setGetExcelListFromStore] = useState(false)
    const [excelList, seExcelList] = useState([])

    useEffect(() => {
        if (getExcelListFromStore) {
            seExcelList(store.videoStore.ExcelListForSingleCampaign);
            setGetExcelListFromStore(false)
        }
    }, [getExcelListFromStore])

    function onSaveHandler() {
        const jsonData = []
        const editor = store.videoStore.editorElements;
        editor.map((item) => {
            if (item.type === "text") {
                const textData = {}
                textData.id = item.id;
                textData.isVisible = item.isVisible;
                textData.type = item.type;
                textData.placement = item.placement;
                textData.timeFrame = item.timeFrame;
                textData.properties = item.properties;
                jsonData.push(textData);

            } else if (item.type === "button") {
                const buttonData = {}
                buttonData.id = item.id;
                buttonData.isVisible = item.isVisible;
                buttonData.type = item.type;
                buttonData.placement = item.placement;
                buttonData.timeFrame = item.timeFrame;
                buttonData.properties = item.properties;
                jsonData.push(buttonData);
            }
            else if (item.type === "video") {
                const videoData = {}
                videoData.id = item.id;
                videoData.isVisible = item.isVisible;
                videoData.type = item.type;
                videoData.placement = item.placement;
                videoData.timeFrame = item.timeFrame;
                videoData.properties = item.properties;
                jsonData.push(videoData);
            }
            else if (item.type === "audio") {
                const speechData = {}
                speechData.id = item.id;
                speechData.isVisible = item.isVisible;
                speechData.type = item.type;
                speechData.placement = item.placement;
                speechData.timeFrame = item.timeFrame;
                speechData.properties = item.properties;
                jsonData.push(speechData);
            }
        })
        const data = {
            jsonData: jsonData,
            animationData: JSON.stringify(store.videoStore.animations),
            campaignId: id
        }
        setApiCallLoader(true)
        store.videoStore.JsonUpdate({ data, setApiCallLoader, isCallFromVideoReplace: false })
    }
    function replacePlaceholders(str, replacements) {
        let tempString = str
        replacements.map(item => {
            tempString = tempString.replaceAll(Object.keys(item), item[Object.keys(item)])
        })
        return tempString
    }
    const [xmlVariable, setXmlVariable] = useState([])
    const [inputPopUpForXmlData, setInputPopUpForXmlData] = useState(false)
    const [XmlVariableData, setXmlVariableData] = useState([]);
    function redirectToPreview() {

        localStorage.removeItem("previewData")
        const jsonData = []
        const editor = JSON.parse(JSON.stringify(store.videoStore.editorElements));
        editor.map((item) => {
            if (item.type === "text") {
                const textData = {}
                textData.id = item.id;
                textData.isVisible = item.isVisible;
                textData.type = item.type;
                textData.placement = item.placement;
                textData.timeFrame = item.timeFrame;
                textData.properties = item.properties;
                if (item.properties.text.match(/\{\{(.*?)\}\}/g)) {
                    textData.properties.text = replacePlaceholders(item.properties.text, XmlVariableData)
                }
                jsonData.push(textData);

            } else if (item.type === "button") {
                const buttonData = {}
                buttonData.id = item.id;
                buttonData.isVisible = item.isVisible;
                buttonData.type = item.type;
                buttonData.placement = item.placement;
                buttonData.timeFrame = item.timeFrame;
                buttonData.properties = item.properties;
                jsonData.push(buttonData);
            }
            else if (item.type === "video") {
                const videoData = {}
                videoData.id = item.id;
                videoData.isVisible = item.isVisible;
                videoData.type = item.type;
                videoData.placement = item.placement;
                videoData.timeFrame = item.timeFrame;
                videoData.properties = item.properties;
                jsonData.push(videoData);
            }
            else if (item.type === "audio") {
                const speechData = {}
                speechData.id = item.id;
                speechData.isVisible = item.isVisible;
                speechData.type = item.type;
                speechData.placement = item.placement;
                speechData.timeFrame = item.timeFrame;
                speechData.properties = item.properties;
                jsonData.push(speechData);
            }
        })
        const data = {
            jsonData: jsonData,
            animationData: JSON.stringify(store.videoStore.animations),
            campaignId: id,
            volumeOfVideo: store.videoStore.volumeOfVideo
        }
        localStorage.setItem("previewData", JSON.stringify(data))
        window.open('/video/preview', '_blank')
    }


    function checkExcelVariableUse() {
        let variable = []
        const editor = JSON.parse(JSON.stringify(store.videoStore.editorElements))
        editor.map((item) => {
            if (item.type === "text") {
                if (item.properties.text.match(/\{\{(.*?)\}\}/)) {
                    variable = [...variable, ...item.properties.text.match(/\{\{(.*?)\}\}/g)]
                }
            }
        })
        setXmlVariable([...new Set(variable)])
        if ([...new Set(variable)].length > 0) {
            setInputPopUpForXmlData(true)
        } else {
            redirectToPreview()
        }
    }
    function storeValueForVariable(key, value) {
        const keyExists = XmlVariableData.some(item => Object.keys(item)[0] === key);
        if (keyExists) {
            const updatedXmlVariableData = XmlVariableData.map(item => {
                if (Object.keys(item)[0] === key) {
                    return { [key]: value };
                }
                return item;
            });
            setXmlVariableData(updatedXmlVariableData);
        } else {
            setXmlVariableData([...XmlVariableData, { [key]: value }]);
        }
    }
    const [error, setError] = useState({})
    const [runValidatorForPassword, setRunValidatorForPassword] = useState(false)
    function verifyXmlData() {
        const isError = validationForExcelVariable()
        setRunValidatorForPassword(true)
        if (isError) return
        // if (Object.keys(error).length === 0) {
        setRunValidatorForPassword(false)
        setInputPopUpForXmlData(false)
        redirectToPreview()
        setXmlVariableData([])
        // }
    }
    function validationForExcelVariable() {
        let error = {}
        let isError = false
        let XmlVariableDataArray = []
        XmlVariableData.map((item) => { XmlVariableDataArray = [...XmlVariableDataArray, ...Object.keys(item)] })
        xmlVariable.map((item_I) => {
            if (XmlVariableDataArray.includes(item_I)) {
                if (XmlVariableData.some(obj => Object.values(obj).some(value => value.length < 1))) {
                    if (XmlVariableData.filter(obj => Object.values(obj).some(value => value.length < 1)).length > 0) {
                        XmlVariableData.filter(obj => Object.values(obj).some(value => value.length < 1)).map((item) => {
                            error[Object.keys(item)[0]] = `${Object.keys(item)[0].replaceAll("{{", "").replaceAll("}}", "")} is Required`;
                            isError = true
                        })
                    }
                } else {
                    delete error[item_I]
                    isError = false
                }
            } else {
                error[item_I] = `${item_I.replaceAll("{{", "").replaceAll("}}", "")} is Required`;
                isError = true
            }
        })
        setError(error)
        return isError
    }
    useEffect(() => {
        if (runValidatorForPassword) {
            validationForExcelVariable()
        }
    }, [XmlVariableData])

    const [canvasWidth, setcanvasWidth] = useState(100)
    return (
        <>
            {inputPopUpForXmlData &&
                <div className='w-[100vw] h-[100vh] fixed top-0 left-0 z-[21]'>
                    <div className='w-[100%] h-[100%] relative flex justify-center items-center'>
                        <div onClick={() => { setInputPopUpForXmlData(false) }} className='w-[100%] h-[100%] absolute bg-[black] opacity-[0.7] top-0 left-0 z-[1]'></div>
                        <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
                            <div className="w-[500px] bg-[#272836] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-[20px]">
                                <div className="flex items-center justify-between">
                                    <h1 className="font-Inter font-semibold text-lg text-[#FFFFFF]">Data For Preview</h1>
                                    <img src={plus} alt="" onClick={() => { setInputPopUpForXmlData(false) }} className="cursor-pointer rotate-45" />
                                </div>
                                <div className="my-[15px] max-h-[600px] overflow-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent">
                                    {xmlVariable.map((item, i) =>
                                        <div key={i} className="w-[100%] mb-[20px] relative">
                                            <label
                                                htmlFor="campaign"
                                                className="font-Inter text-[14px] font-semibold text-[#fff] tracking-[0.32px] leading-[16.94px]"
                                            >
                                                {item.replaceAll("{{", "").replaceAll("}}", "")} <span className="text-[#FF5364]">*</span>
                                            </label>
                                            <div className=" relative">
                                                <input
                                                    name="excelName"
                                                    className=" w-full rounded-[6px] border border-[#3B3B4F]  bg-transparent text-[#ABABC0] placeholder:text-[#ABABC0] py-[16.5px] mt-[10px] pl-[10px] pr-[33px] text-[14px] outline-none font-Inter font-normal tracking-[0.48] leading-[16.94px]"
                                                    placeholder={`Enter ${item.replaceAll("{{", "").replaceAll("}}", "")}`}
                                                    maxLength={40}
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        storeValueForVariable(item, e.target.value)
                                                    }}
                                                    autoFocus
                                                ></input>
                                                <p className="text-[12px] pt-1 absolute bottom-[-18px] leading-[14.52px] font-medium text-[#FF5364]">
                                                    {error[item] ? error[item] : ""}
                                                </p>
                                            </div>
                                            {/* {excelNameError?.length > 0 && (
                                       <img
                                           src={exclamation_mark}
                                           alt="*"
                                           className="absolute top-[50px] right-[10px] w-[20px]"
                                       />
                                   )} */}
                                        </div>
                                    )}
                                </div>
                                <div className="flex justify-center">
                                    <button onClick={(e) => { verifyXmlData() }} className="bg-[#5446D0] flex justify-center items-center gap-[10px] py-[8px] px-[32px] rounded font-Inter font-semibold text-base text-[#FFFFFF] outline-none" >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
            {/* API Loader */}
            <div className={`${apiCallLoader ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}>
                <APICallLoader />
            </div>
            {/* Action on Excel Modal */}
            {excelModel &&
                <ExcelListModal setExcelModel={setExcelModel} setAddExcelModel={setAddExcelModel} setGetExcelListFromStore={setGetExcelListFromStore} excelList={excelList} />
            }
            {/* Add Excel Modal */}
            {addExcelModel &&
                <AddExcelModel setAddExcelModel={setAddExcelModel} setApiCallLoader={setApiCallLoader} setGetExcelListFromStore={setGetExcelListFromStore} />
            }

            <div className='w-[100%] h-[100vh] bg-[#2C2D3C]'>
                <p className='font-[POPPINS] hidden'></p>
                {/* navbar code starts */}
                <div className="bg-[#1C1C26] px-[20px] h-[60px] 2xl:h-[80px] flex items-center justify-between border-b border-[#2C2D3C]">
                    <div className="flex items-center ">
                        <p className="text-base font-Inter font-semibold text-white pr-[20px] border-r-[1px] border-[#3B3B4F] cursor-pointer" onClick={() => { navigate('/videos') }}>
                            {/* <Link href='/dashboard'> */}
                            <img src={logo} alt="logo" className=" h-[25px] 2xl:h-auto w-auto object-contain 2xl:ml-1" />
                            {/* </Link> */}
                        </p>
                        <p className="font-Inter font-normal text-base text-[#ABABC0] px-[20px] pr-[20px] border-r-[1px] border-[#3B3B4F] flex items-center gap-[8px]">
                            <span className='capitalize'>{campaignData.campaignName}</span>
                        </p>
                    </div>

                    <div className="flex gap-[10px] 2xl:gap-[15px] justify-center items-center">
                        <div className="pr-[10px] border-r-[1px] border-[#272836]">
                            {/* <Link href={'/dashboard/setting'} className="bg-[#5446D0] px-[14px] py-[7px] 2xl:py-[8px] uppercase rounded font-Inter font-semibold text-[14px] 2xl:text-base text-[#FFFFFF] cursor-pointer outline-none">
                                {localStorage.getItem(__USER_DATA) !== null ? JSON.parse(localStorage.getItem(__USER_DATA)).name[0] : "User"}
                            </Link> */}
                        </div>
                        {/* onClick={() => { xmlVariable !== undefined ? checkExcelVariableUse() : handleCreate() }} */}
                        <button onClick={() => { xmlVariable !== undefined ? checkExcelVariableUse() : redirectToPreview() }} className="border border-[#5446D0] text-[14px] 2xl:text-[16px] flex justify-center items-center  py-[4px] px-[16px] 2xl:py-[7px] 2xl:px-[32px] rounded font-Inter font-semibold text-[#FFFFFF] outline-none">
                            <span>Preview</span>
                        </button>

                        {/* onClick={handleCreate} */}
                        <button onClick={() => { setExcelModel(true) }} className="border cursor-pointer border-[#5446D0] text-[14px] 2xl:text-[16px] flex justify-center items-center  py-[4px] px-[16px] 2xl:py-[7px] 2xl:px-[32px] rounded font-Inter font-semibold text-[#FFFFFF] outline-none">
                            {/* <img src={export_icon} alt='Export' className='max-2xl:w-[18px] max-2xl:h-[18px]' />  */}
                            Export
                        </button>

                        <button onClick={() => { onSaveHandler() }} className="bg-[#5446D0] text-[14px] 2xl:text-[16px] flex justify-center items-center py-[5px] px-[16px] 2xl:py-[8px] 2xl:px-[32px] rounded font-Inter font-semibold text-[#FFFFFF] outline-none">
                            {/* <img src={save_icon} alt="save" className='max-2xl:w-[18px] max-2xl:h-[18px]' /> */}
                            <span>Save</span>
                        </button>
                    </div>
                </div>
                {/* navbar code end */}
                <div className="w-[100%] h-[calc(100vh-60px)] 2xl:h-[calc(100vh-80px)] flex bg-[#1C1C26]">

                    {/* menu code starts */}
                    <div className="2xl:h-[calc(100vh-80px)] h-[calc(100vh-60px)]">
                        <ul className="bg-[#1C1C26] h-full w-[60px] 2xl:w-[80px]">
                            {menuOptions.map((item, i) => {
                                return (
                                    <li key={item.name} className={`h-[60px] 2xl:h-[80px] w-[60px] 2xl:w-[80px] flex flex-col items-center justify-center ${item.slug === store.videoStore.selectedMenuOption ? "bg-[#272836]" : ""}`}>
                                        <button onClick={() => { if (item.name === "Excels") { setExcelModel(true) } else { store.videoStore.setSelectedMenuOption(item.slug) } }} className={`flex flex-col items-center outline-none`}>
                                            <img src={item.image} alt={item.name} style={{ width: "20px", height: "20px", color: store.videoStore.selectedMenuOption === item.slug ? "#000" : "#444" }} />
                                            <div className={`text-[12px] mt-[2px] 2xl:text-[14px] hover:text-white font-Inter font-medium text-center ${store.videoStore.selectedMenuOption === item.slug ? "text-white" : "text-[#ABABC0]"}`}> {item.name}
                                            </div>
                                        </button>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                    {/* menu code ends */}

                    {/* editor options and canvas start */}
                    <div className=" w-[100%]">
                        <div className={`grid grid-cols-12 ${showTimeline ? "2xl:h-[calc(100vh-250px-80px)] h-[calc(100vh-192px-60px)]" : "2xl:h-[calc(100vh-47px-80px)] h-[calc(100vh-47px-60px)]"}  bg-[#17171E]`}>

                            {/* left editor options side bar start */}
                            <div className="col-span-3 2xl:col-span-2 min-h-[100%] overflow-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent bg-[#272836]">
                                <EditorOptions />
                            </div>
                            {/* left editor options side bar end */}

                            {/* canvas start */}

                            {/* <div style={{ width: '100%' }} className="px-[10px] py-[10px] col-span-9 2xl:col-span-10 2xl:p-[30px]  overflow-auto  relative scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent flex justify-center" id="container">
                                <canvas id="canvas" className=" w-full my-auto" ></canvas>
                                <div className=" absolute top-0 invisible ">
                                    <ElementsPanel />
                                </div>
                            </div> */}
                            <div className={`p-[10px] 2xl:p-[30px] col-span-9 2xl:col-span-10 overflow-auto h-[calc(100%-10px)] scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent`}>

                                <div style={{ width: `${canvasWidth}%`, }}
                                    id="container">
                                    <div style={{ width: '100%', height: '100%' }} className=''>

                                        <canvas id="canvas"></canvas>
                                        <div className=" absolute top-0 invisible ">
                                            <ElementsPanel />
                                        </div>
                                    </div>
                                </div>
                                <div className={`flex items-center bg-[#272836] text-[#ababc0] min-h-[30px]  rounded-md absolute ${showTimeline ? `bottom-[190px] 2xl:bottom-[250px]` : 'bottom-[45px]'} right-[45px]`}>
                                    <button className='px-3 py-[3px] border-r border-[#17171e]' onClick={() => {
                                        setcanvasWidth(Number(canvasWidth) - Number(1));
                                        setTimeout(() => {
                                            store.videoStore.refreshElements();
                                        }, 100);
                                    }}><img src={magnifyingminus} className='w-[18px]' alt='magnifying-minus'></img></button>
                                    {/* <input className='px-3 w-[60px] outline-none bg-transparent text-center text-[#ABABC0] placeholder:text-[#ABABC0]' value={Number(Math.round(store.videoStore.videoSizePercentage))} onChange={(e) => { 
                                        if(e.target.value <= 150){
                                          setcanvasWidth(e.target.value?.replace(/[^0-9]/g, "")?.trimStart())
                                         setTimeout(() => {
                                            store.videoStore.refreshElements();
                                        }, 100);    
                                        }
                                    }}></input> */}
                                    <p>{Math.round(store.videoStore.videoSizePercentage)}%</p>
                                    <button disabled={canvasWidth >= 150} className='px-3 py-[3px] border-l border-[#17171e]' onClick={() => {
                                        setcanvasWidth(Number(canvasWidth) + Number(1));
                                        setTimeout(() => {
                                            store.videoStore.refreshElements();
                                        }, 100);
                                    }}><img src={magnifyingplus} className='w-[18px]' alt='magnifying-plus'></img></button>
                                </div>
                            </div>


                            {/* canvas end */}

                        </div>
                        <div className={`bg-[#17171E] w-full showTimeLine transform ${showTimeline ? "h-[185px] 2xl:h-[250px] showTimeLine" : " h-[45px] hideTimeLine"}  flex  border-t border-[#2C2D3C] relative`}>
                            <button className={`bg-[#272836]  outline-none w-[40px] h-[30px] self-end absolute top-[-30px] right-0 rounded-tl-[6px] p-[5px] flex justify-center items-center cursor-pointer`} onClick={() => { setShowTimeline(!showTimeline) }}>
                                <img src={arrow} alt='arrow' className={` duration-500 transform ${showTimeline ? "rotate-[180deg]" : "rotate-[0deg]"}`} />
                            </button>
                            <div className="w-[100%]">
                                <TimeLine showTimeline={showTimeline} />
                            </div>
                        </div>
                    </div>
                    {/* editor options and canvas end */}
                </div>
            </div>
        </>
    )
}

export default observer(Editor)