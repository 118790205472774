import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../utility/hooks/UseStore";
import video_icon from "../../../assets/icons/video.svg"
import text_icon from "../../../assets/icons/text.svg"
import button_icon from "../../../assets/icons/button.svg"
import text_to_speecg_icon from "../../../assets/icons/text_to_speech.svg"
import lock_open_icon from "../../../assets/icons/lock_open.svg"
import close_eye_icon from "../../../assets/icons/close_eyes.svg"
import eye_icon from "../../../assets/icons/eye.svg"
import delete_icon from "../../../assets/icons/delete.svg"

export const Element = observer((props) => {


    const mainStore = useStore();
    const store = mainStore.videoStore

    const { element } = props;
    const isSelected = store.selectedElement?.id === element.id;
    // let ImageIcon;
    // if (element.type === "video") {
    //     ImageIcon = <Image src={VideoIcon} alt="VideoIcon" className="w-[20px] h-[20px]" />
    // } else if (element.type === "text") {
    //     ImageIcon = <Image src={TextIcon} alt="TextIcon" className="w-[20px] h-[20px]" />
    // } else if (element.type === "button") {
    //     ImageIcon = <Image src={BtnIcon} alt="BtnIcon" className="w-[20px] h-[20px]" />
    // } else if (element.type === "audio") {
    //     ImageIcon = <Image src={TTSIcon} alt="TTSIcon" className="w-[20px] h-[20px]" />
    // }

    return (
        <div
            className={`flex px-[12px] flex-row justify-between items-center ${isSelected ? "bg-[#272836]" : ""} w-full border-b border-[#2C2D3C]  py-[5px]`}
            key={element.id}
            onClick={() => {
                store.setSelectedElement(element);
            }}
        >
            <img src={element.type === "video" ? video_icon : element.type === "text" ? text_icon : element.type === "button" ? button_icon : text_to_speecg_icon} alt="VideoIcon" className="w-[20px] h-[20px]" />
            {/* {ImageIcon && ImageIcon} */}
            <div>
                {element.type === "video" ? 
                    <video
                        className="opacity-0 max-w-[20px] max-h-[20px]"
                        src={element.properties.src}
                        onLoad={() => {
                            store.refreshElements();
                        }}
                        onLoadedData={() => {
                            store.refreshElements();
                        }}
                        height={20}
                        width={20}
                        id={element.properties.elementId}
                    ></video>
                 : null}
                {element.type === "image" ? 
                    <img
                        className="opacity-0 max-w-[20px] max-h-[20px]"
                        alt="img"
                        src={element.properties.src}
                        onLoad={() => {
                            store.refreshElements();
                        }}
                        onLoadedData={() => {
                            store.refreshElements();
                        }}
                        height={20}
                        width={20}
                        id={element.properties.elementId}
                    ></img>
                 : null}
                {element.type === "audio" ? 
                    <audio
                        className="opacity-0 max-w-[20px] max-h-[20px]"
                        src={element.properties.src}
                        onLoad={() => {
                            store.refreshElements();
                        }}
                        onLoadedData={() => {
                            store.refreshElements();
                        }}
                        id={element.properties.elementId}
                    ></audio>
                 : null}
            </div>
            <div>
                <button className={`${element.type === "video" ? "opacity-[0.2]" : ""} p-[10px] outline-none`}><img src={lock_open_icon} alt="Lock" /></button>
                <button
                    onClick={(e) => {
                        store.isVisible(element.id, element.isVisible === true ? false : true)
                    }}
                    className={`${element.type === "video" ? "cursor-not-allowed opacity-[0.2]" : "cursor-pointer"} p-[10px] outline-none`}
                    disabled={element.type === 'video'}
                >
                    {/* {(element.isVisible === true) ? <Image src={Eye} alt="Eye" /> : <Image src={CloseEye} alt="CloseEye" />} */}
                    {(element.timeFrame.start >= store.maxTime) ? <img src={close_eye_icon} alt="CloseEye" /> : <img src={eye_icon} alt="Eye" />}

                </button>
                <button
                    className={`${element.type === "video" ? "cursor-not-allowed opacity-[0.2]" : "cursor-pointer"} p-[10px] outline-none`}
                    onClick={(e) => {
                        store.removeEditorElement(element.id);
                        store.refreshElements();
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    disabled={element.type === 'video'}
                >
                    <img src={delete_icon} alt="Delete" />
                </button>
            </div>
        </div >
    );
});
