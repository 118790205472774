import './App.css';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import Router from '../src/router/Router';
import { StoreContext } from './store';
import LoaderRing from './components/ui/common/LoaderRing';
import { Store } from './store/Store';

function App() {

    // const store = useContext(StoreContext);
    // const store = React.useRef(null);

    const [store] = useState(new Store())

    return (
        <StoreContext.Provider value={store}>
            <div className='app select-none overflow-hidden'>
                <Suspense fallback={<LoaderRing />}>
                    <Router />
                </Suspense>
                <Toaster position="top-right" reverseOrder={false}>
                    {(t) => (
                        <ToastBar toast={t}>
                            {({ icon, message }) => (
                                <>
                                    {t.type !== 'loading' && (
                                        <button className='rounded-full focus:outline-none flex items-center' onClick={() => toast.dismiss(t.id)} >
                                            {icon}
                                            {message}
                                        </button>
                                    )}
                                </>
                            )}
                        </ToastBar>
                    )}
                </Toaster>
            </div>
        </StoreContext.Provider>
    );
}

export default App;
