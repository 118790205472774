import React from "react";
import { observer } from "mobx-react";
import { Element } from "../entity/Element";
import { useStore } from "../../../utility/hooks/UseStore";

export const ElementsPanel = observer((props) => {

  const mainStore = useStore();
  const store = mainStore.videoStore;
  return (
    <div className="min-w-[218px] max-h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#5446D0] scrollbar-track-transparent">
      {/* <div className="flex flex-row justify-between">
        <div className="text-sm px-[22px] py-[7px] font-semibold">Elements</div>
      </div> */}
      <div className="font-Inter font-normal text-sm text-[#ABABC0]">
        <p className="px-[12px] py-[10px] w-full border-b border-[#2C2D3C] text-center h-[45px]">Layers</p>
        <p className="px-[12px] py-[10px] w-full border-b border-[#2C2D3C] text-center h-[40px]">Layers</p>
      </div>
      <div className="flex flex-col w-full">
        {store.editorElements.map((element) => (
          <Element key={element.id} element={element} />
        ))}
      </div>
    </div>
  );
});
